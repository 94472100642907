import React, { useEffect, useState } from "react";
import SearchViewModel from "../../view-model/search/SearchViewModel";
import "react-datepicker/dist/react-datepicker.css";
import { TailSpin } from "react-loader-spinner";
import SuccessAlert from "../../components/SuccessAlert";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import SearchApi from "../../../data/search/SearchApi";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import { useLocation } from "react-router";
import Select from "react-select";
import { useSelector } from "react-redux";
import { RootState } from "../../../infrastructure/redux/reducer";
import LocalStorageService from "../../utils/LocalStorageService";
import { Row } from "react-bootstrap";
import useUser from "../../../infrastructure/hooks/useUser";
import CreateSearchErrorModal from "./CreateSearchErrorModal";
import UserSettingApi from "../../../data/usersettings/UserSettingApi";

type SearchComponentState = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  ageRangeFrom: string;
  ageRangeTo: string;
  dateOfLoss: string;
  address: string;
  city: string;
  zipCode: string;
  employer: string;
  caseId: number | null;
  selectedCase: any;
  newCaseName: string;
  existCaseOptionSelected: boolean;
  email: string[];
  phone: string[];
  emailFormValues: string[];
  phoneFormValues: string[];
  usernameFormValues: string[];
  username: string[];
  validation: any;
  isLoading: boolean;
  isShowError: boolean;
  errorMessages: string[];
  isSuccess: boolean;
  selectedState: any;
  isClaimOfficeRequired: boolean;
  mustFillClaimOffice: boolean;
};

const CreateSearch: React.FC = () => {
  const ReRunSearch = useSelector((state: RootState) => state.search);

  const { viewModel, subscription } = useViewModel(SearchViewModel, [
    new SearchApi(new HttpClient()),
    new UserSettingApi(new HttpClient())]);
  const [state, setState] = useState<SearchComponentState>({
    firstName: "",
    lastName: "",
    caseId: null,
    selectedCase: null,
    newCaseName: "",
    existCaseOptionSelected: false,
    email: [],
    address: "",
    city: "",
    ageRangeFrom: "",
    ageRangeTo: "",
    dateOfBirth: "",
    dateOfLoss: "",
    emailFormValues: [],
    employer: "",
    phone: [],
    phoneFormValues: [],
    usernameFormValues: [],
    zipCode: "",
    username: [],
    validation: {},
    isSuccess: false,
    isLoading: false,
    isShowError: false,
    errorMessages: [],
    selectedState: null,
    isClaimOfficeRequired: false,
    mustFillClaimOffice: false
  });

  const { user } = useUser();
  const search = useLocation().search;
  const caseId = new URLSearchParams(search).get("caseid");
  const today = new Date();
  const two_years_ago = new Date(today.setFullYear(today.getFullYear() - 2))
  const two_years_ago_str = two_years_ago.toISOString().slice(0, 10);
  const max_length_name_feilds = 25;

  useEffect(() => {
    viewModel.getUserClaimOfficeData();
    const subscriber = subscription.subscribe((d) =>
      setState({ ...state, ...d.data })
    );
    if (caseId) {
      viewModel.onCaseIdQueryParamChanged(caseId);
    }
    viewModel.listCases();
    viewModel.listStates();
    if (ReRunSearch.caseId !== null) {
      viewModel.initializeSearchWithRereunSearch(ReRunSearch);
      viewModel.onCaseIdQueryParamChanged(ReRunSearch.caseId);
    }
    return () => {
      subscriber.unsubscribe();
    };
  }, []);

  let caseOptions = [];
  let stateOptions = [];

  if (viewModel.caseList) {
    caseOptions = viewModel.caseList.map(
      (e) =>
      ({
        value: e.caseId,
        label: e.name,
      } as any)
    );
  }

  if (viewModel.stateList) {
    stateOptions = viewModel.stateList.map(
      (e) =>
      ({
        value: e.id,
        label: e.name,
      } as any)
    );
  }

  return (
    <div className="container">
      {state.isSuccess && (
        <div className="mt-5">
          <SuccessAlert
            headerMessage="SearchHeaderMessage"
            bodyMessage="SearchBodyMessage"
          />
        </div>
      )}
      {!state.isSuccess && (
        <div className="">
          <div className="search-header">
            {viewModel.caseName && (
              <div className="breadcrumb-div">
                <a href="/case" className="breadcrumb-item active">
                  Cases
                </a>
                <p className="breadcrumb-seperator-item">/</p>
                <a
                  href={"/search?caseid=" + state.caseId}
                  className="breadcrumb-item active"
                >
                  {viewModel.caseName}
                </a>
                <p className="breadcrumb-seperator-item">/</p>
                <p className="breadcrumb-item">New Search</p>
              </div>
            )}
            <div className="search-title">
              <h2 className="search-title-text">Create Search Request</h2>
            </div>
          </div>
          <div className="create-search-div">
            <div className="form-style-one">
              <div className="row gx-xxl-5">
                <div className="col-lg-8 order1">
                  <form
                    id="contact-form"
                    className="main-form"
                    onSubmit={(e: any) => e.preventDefault()}
                  >
                    <div className="section-div">
                      <p className="section-title text-center">Select Case</p>
                      <div className="case-selection">
                        <input type="radio" id="new-case" name="case-selection" value="new-case" checked={!state.existCaseOptionSelected && !caseId ? true : false} disabled={caseId ? true : false} onChange={() => viewModel.onCaseExistSelectionChange(viewModel.NewCase)} />
                        <label htmlFor="new-case">New Case</label>
                        <br />
                        <input type="radio" id="exist-case" name="case-selection" value="exist-case" checked={state.existCaseOptionSelected || caseId ? true : false} onChange={() => viewModel.onCaseExistSelectionChange(viewModel.ExistCase)} />
                        <label htmlFor="exist-case">Existing Case</label>
                        <br />
                      </div>
                      {(caseId || state.existCaseOptionSelected) &&
                        <div className="col-md-6">
                          <div className="input-group-meta form-group modal-select-input form-floating">
                            <Select
                              id="caseId"
                              isDisabled={caseId ? true : false}
                              isClearable
                              placeholder="Select Case"
                              onChange={(e: any) =>
                                viewModel.onCaseIdQueryChanged(e)
                              }
                              value={state.selectedCase}
                              className={`${state.validation.caseId
                                ? "form-control form-select is-invalid"
                                : "form-control form-select"
                                }`}
                              onBlur={() => {
                                viewModel.validateCase;
                              }}
                              options={caseOptions}
                            />
                            <label className="form-label">Case</label>
                          </div>
                        </div>
                      }
                      {(!caseId && !state.existCaseOptionSelected) &&
                        <div className="col-md-6 form-floating">
                          <input autoComplete="new-password"
                            type="text"
                            placeholder="Case Name"
                            name="newCaseName"
                            id="newCaseName"
                            onChange={viewModel.onQueryChanged}
                            value={state.newCaseName}
                            className={`form-control ${state.validation.caseId ? "is-invalid" : ""
                              }`}
                          />
                          <label className="form-label">
                            Enter Case Name
                          </label>

                        </div>
                      }
                      {state.validation.caseId && (
                        <div className="invalid-feedback">
                          {state.validation.caseId}
                        </div>
                      )}

                    </div>

                    <hr className="search-divider mb-30 mt-30"></hr>

                    <div className="section-div">
                      <h3 className="section-title text-center">
                        Personal Information
                      </h3>

                      <div className="row input-row">
                        <div className="col-md-6">
                          <div className="input-group-meta form-group mb-30 form-floating">
                            <input autoComplete="new-password"
                              type="text"
                              maxLength={max_length_name_feilds}
                              placeholder="First Name"
                              name="firstName"
                              id="firstName"
                              onChange={viewModel.onQueryChanged}
                              value={state.firstName}
                              className={`form-control ${state.validation.firstName ? "is-invalid" : ""
                                }`}
                              onBlur={() => {
                                viewModel.validateFirstName();
                              }}
                            />
                            <label className="form-label">First Name</label>

                            {state.validation.firstName && (
                              <div className="invalid-feedback">
                                {state.validation.firstName}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-group-meta form-group mb-30 form-floating">
                            <input autoComplete="new-password"
                              type="text"
                              maxLength={max_length_name_feilds}
                              placeholder="Last Name"
                              name="lastName"
                              id="lastName"
                              onChange={viewModel.onQueryChanged}
                              value={state.lastName}
                              className={`form-control ${state.validation.lastName ? "is-invalid" : ""
                                }`}
                              onBlur={() => {
                                viewModel.validateLastName();
                              }}
                            />
                            <label className="form-label">Last Name</label>

                            {state.validation.lastName && (
                              <div className="invalid-feedback">
                                {state.validation.lastName}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          {state.emailFormValues.map((emailItem, index) => (
                            <div
                              className="input-group-meta form-group"
                              key={index}
                            >
                              <div className="remove-extra-input-div form-floating">
                                <input autoComplete="new-password"
                                  type="text"
                                  name="your_email"
                                  id="your_email"
                                  pattern="[^@]+@[^@]+.[a-zA-Z]{2,6}"
                                  placeholder="Email"
                                  onChange={(
                                    e: React.FormEvent<HTMLInputElement>
                                  ): void => {
                                    viewModel.onEmailQueryChanged(
                                      e.currentTarget.value,
                                      index
                                    );
                                  }}
                                  value={emailItem || ""}
                                  className={`form-control ${state.validation.email &&
                                    state.validation.email[index]
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                />
                                <label className="form-label">Email</label>
                                {state.validation.email &&
                                  state.validation.email[index] && (
                                    <div className="side-invalid-feedback mb-2">
                                      {state.validation.email[index]}
                                    </div>
                                  )}
                                {index || state.emailFormValues.length > 1 ? (
                                  <span
                                    className="search-input-icon bi bi-trash"
                                    onClick={() =>
                                      viewModel.onEmailQueryRemoved(index)
                                    }
                                  ></span>
                                ) : null}
                              </div>
                            </div>
                          ))}
                          <div className="extra-input-add-div">
                            <img
                              className="extra-input-add-icon"
                              src="/images/add-input.svg"
                              alt=""
                              onClick={() => viewModel.onEmailQueryAdded()}
                            />
                            <p
                              className="extra-input-add-text"
                              onClick={() => viewModel.onEmailQueryAdded()}
                            >
                              Add New Email
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          {state.phoneFormValues.map((phoneItem, index) => (
                            <div
                              className="input-group-meta form-group"
                              key={index}
                            >
                              <div className="remove-extra-input-div form-floating">
                                <input autoComplete="new-password"
                                  type="text"
                                  pattern="[0-9]{10}"
                                  name="your_phone"
                                  id="your_phone"
                                  placeholder="Phone"
                                  onChange={(e: React.FormEvent<HTMLInputElement>): void => {
                                    e.currentTarget.value = e.currentTarget.value.replaceAll(/[^0-9]/g, '');
                                    viewModel.onPhoneQueryChanged(e.currentTarget.value, index);
                                  }}
                                  value={phoneItem || ""}
                                  className={`form-control ${state.validation.phone &&
                                    state.validation.phone[index]
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                />
                                <label className="form-label">Phone</label>
                                {state.validation.phone &&
                                  state.validation.phone[index] && (
                                    <div className="side-invalid-feedback mb-2">
                                      {state.validation.phone[index]}
                                    </div>
                                  )}
                                {index || state.phoneFormValues.length > 1 ? (
                                  <span
                                    className="search-input-icon bi bi-trash"
                                    onClick={() =>
                                      viewModel.onPhoneQueryRemoved(index)
                                    }
                                  ></span>
                                ) : null}
                              </div>
                              {/* {state.validation.phone &&
                                state.validation.phone[index] && (
                                  <div className="invalid-feedback mb-2">
                                    {state.validation.phone[index]}
                                  </div>
                                )} */}
                            </div>
                          ))}
                          {/* {state.validation.phone && (
                            <div className="invalid-feedback">
                              {state.validation.phone}
                            </div>
                          )} */}
                          <div className="extra-input-add-div">
                            <img
                              className="extra-input-add-icon"
                              src="/images/add-input.svg"
                              alt=""
                              onClick={() => viewModel.onPhoneQueryAdded()}
                            />
                            <p
                              className="extra-input-add-text"
                              onClick={() => viewModel.onPhoneQueryAdded()}
                            >
                              Add New Phone
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          {state.usernameFormValues.map(
                            (usernameItem, index) => (
                              <div
                                className="input-group-meta form-group"
                                key={index}
                              >
                                <div className="remove-extra-input-div form-floating">
                                  <input autoComplete="new-password"
                                    type="text"
                                    name="your_username"
                                    id="your_username"
                                    placeholder="Username"
                                    onChange={(
                                      e: React.FormEvent<HTMLInputElement>
                                    ): void => {
                                      viewModel.onUsernameQueryChanged(
                                        e.currentTarget.value,
                                        index
                                      );
                                    }}
                                    value={usernameItem || ""}
                                    className={`form-control ${state.validation.username &&
                                      state.validation.username[index]
                                      ? "is-invalid"
                                      : ""
                                      }`}
                                  />
                                  <label className="form-label">Username</label>
                                  {state.validation.username &&
                                    state.validation.username[index] && (
                                      <div className="side-invalid-feedback mb-2">
                                        {state.validation.username[index]}
                                      </div>
                                    )}
                                  {index ||
                                    state.usernameFormValues.length > 1 ? (
                                    <span
                                      className="search-input-icon bi bi-trash"
                                      onClick={() =>
                                        viewModel.onUsernameQueryRemoved(index)
                                      }
                                    ></span>
                                  ) : null}
                                </div>
                              </div>
                            )
                          )}
                          <div className="extra-input-add-div">
                            <img
                              className="extra-input-add-icon"
                              src="/images/add-input.svg"
                              alt=""
                              onClick={() => viewModel.onUsernameQueryAdded()}
                            />
                            <p
                              className="extra-input-add-text"
                              onClick={() => viewModel.onUsernameQueryAdded()}
                            >
                              Add New Username
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6"></div>

                        <div className="col-md-5">
                          <div className="input-group-meta form-group mb-30 form-floating">
                            <input
                              type="date"
                              max={two_years_ago_str}
                              name="dateOfBirth"
                              id="dateOfBirth"
                              value={state.dateOfBirth}
                              className={`form-control ${state.validation.dateOfBirth ||
                                state.validation.noAgeOrBirthDate
                                ? "is-invalid"
                                : ""
                                }`}
                              onBlur={() => {
                                viewModel.validateDateOfBirth();
                              }}
                              onChange={viewModel.onQueryChanged}
                            />
                            <label className="form-label">Date of birth</label>

                            {state.validation.dateOfBirth && (
                              <div className="invalid-feedback">
                                {state.validation.dateOfBirth}
                              </div>
                            )}
                            {state.validation.noAgeOrBirthDate && (
                              <div className="invalid-feedback">
                                {state.validation.noAgeOrBirthDate}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-1">
                          <p style={{ textAlign: 'center', paddingTop: '7px' }}>or</p>
                        </div>
                        <div className="col-md-3">
                          <div className="input-group-meta form-group mb-30 form-floating">
                            <input
                              type="number"
                              name="ageRangeFrom"
                              placeholder="Age From"
                              id="ageRangeFrom"
                              value={state.ageRangeFrom}
                              className={`form-control ${state.validation.ageRangeFrom ||
                                state.validation.ageRangeFromAndTo
                                ? "is-invalid"
                                : ""
                                }`}
                              onBlur={() => {
                                viewModel.validateAgeRange();
                              }}
                              onChange={viewModel.onQueryChanged}
                            />

                            <label className="form-label">Age From</label>

                            {state.validation.ageRangeFrom && (
                              <div className="invalid-feedback">
                                {state.validation.ageRangeFrom}
                              </div>
                            )}
                            {state.validation.ageRangeFromAndTo && (
                              <div className="invalid-feedback">
                                {state.validation.ageRangeFromAndTo}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="input-group-meta form-group mb-30 form-floating">
                            <input
                              type="number"
                              name="ageRangeTo"
                              placeholder="Age To"
                              id="ageRangeTo"
                              value={state.ageRangeTo}
                              onBlur={() => {
                                viewModel.validateAgeRange();
                              }}
                              className={`form-control ${state.validation.ageRangeTo ? "is-invalid" : ""
                                }`}
                              onChange={viewModel.onQueryChanged}
                            />
                            <label className="form-label">Age To</label>

                            {state.validation.ageRangeTo && (
                              <div className="invalid-feedback">
                                {state.validation.ageRangeTo}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr className="search-divider mb-30 mt-30"></hr>

                    <div className="section-div">
                      <h3 className="section-title text-center">
                        Address Information
                      </h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-group-meta form-group mb-30 form-floating">
                            <input autoComplete="new-password"
                              type="text"
                              placeholder="Street Address"
                              name="address"
                              id="address"
                              onChange={viewModel.onQueryChanged}
                              value={state.address}
                              className={`form-control ${state.validation.address ||
                                state.validation.notEnoughData
                                ? "is-invalid"
                                : ""
                                }`}
                            />
                            <label className="form-label">Street Address</label>

                            {state.validation.address && (
                              <div className="invalid-feedback">
                                {state.validation.address}
                              </div>
                            )}
                            {state.validation.notEnoughData && (
                              <div className="invalid-feedback">
                                {state.validation.notEnoughData}
                              </div>
                            )}
                          </div>
                          {/* <FloatingLabelControl disabled={false} type='text' label='Street Address' id='address' onChange={viewModel.onQueryChanged} value={state.address} /> */}
                        </div>

                        <div className="col-md-6">
                          <div className="input-group-meta form-group mb-30 form-floating">
                            <input autoComplete="new-password"
                              type="text"
                              placeholder="City"
                              name="city"
                              id="city"
                              onChange={viewModel.onQueryChanged}
                              value={state.city}
                              className={`form-control ${state.validation.city ? "is-invalid" : ""
                                }`}
                            />
                            <label className="form-label">City</label>

                            {state.validation.city && (
                              <div className="invalid-feedback">
                                {state.validation.city}
                              </div>
                            )}
                          </div>
                          {/* <FloatingLabelControl disabled={false} type='text' label='City' id='city' onChange={viewModel.onQueryChanged} value={state.city} /> */}
                        </div>
                        <div className="col-md-6">
                          <div className="input-group-meta form-group modal-select-input form-floating">
                            <Select
                              id="stateId"
                              isClearable
                              className="form-control form-select"
                              placeholder="Select State"
                              onChange={(e: any) => viewModel.onStateIdQueryChanged(e)}
                              value={state.selectedState}
                              options={stateOptions}
                            />
                            <label className="form-label">State</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-group-meta form-group mb-30 form-floating">
                            <input autoComplete="new-password"
                              type="text"
                              placeholder="Zip Code"
                              name="zipCode"
                              id="zipCode"
                              onChange={viewModel.onQueryChanged}
                              value={state.zipCode}
                              className={`form-control ${state.validation.zipCode ? "is-invalid" : ""
                                }`}
                            />
                            <label className="form-label">Zip Code</label>

                            {state.validation.zipCode && (
                              <div className="invalid-feedback">
                                {state.validation.zipCode}
                              </div>
                            )}
                          </div>
                          {/* <FloatingLabelControl disabled={false} type='text' label='Zip Code' id='zipCode' onChange={viewModel.onQueryChanged} value={state.zipCode} /> */}
                        </div>

                        <div className="col-md-6">
                          <div className="input-group-meta form-group mb-30 form-floating">
                            <input
                              type="date"
                              name="dateOfLoss"
                              id="dateOfLoss"
                              value={state.dateOfLoss}
                              className={`form-control ${state.validation.dateOfLoss ? "is-invalid" : ""
                                }`}
                              onChange={viewModel.onQueryChanged}
                            />
                            <label className="form-label">Date of Loss </label>

                            {state.validation.dateOfLoss && (
                              <div className="invalid-feedback">
                                {state.validation.dateOfLoss}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-group-meta form-group mb-30 form-floating">
                            <input autoComplete="new-password"
                              type="text"
                              placeholder="Employer"
                              name="employer"
                              id="employer"
                              onChange={viewModel.onQueryChanged}
                              value={state.employer}
                              className={`form-control ${state.validation.employer ? "is-invalid" : ""
                                }`}
                            />
                            <label className="form-label">Employer</label>

                            {state.validation.employer && (
                              <div className="invalid-feedback">
                                {state.validation.employer}
                              </div>
                            )}
                          </div>
                          {/* <FloatingLabelControl disabled={false} type='text' label='Employer' id='employer' onChange={viewModel.onQueryChanged} value={state.employer} /> */}
                        </div>
                      </div>
                    </div>

                    <TailSpin
                      wrapperClass="cover-spin"
                      visible={state.isLoading}
                      height={50}
                      width="50"
                      color="#FF5733"
                      ariaLabel="loading"
                    />

                    {state.isShowError &&
                      state.errorMessages.map((error, i) => {
                        return (
                          <div
                            key={i}
                            className="row my-3 text-danger justify-content-center"
                          >
                            {error}
                          </div>
                        );
                      })}

                    <div className="btn-row">
                      <button
                        name="register"
                        className="submit-btn btn"
                        onClick={(): Promise<void> => viewModel.onClickSearch()}
                        disabled={!user.canCreateSearchRequests || state.mustFillClaimOffice}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>

                <div className="col-lg-4">
                  <div className="hot-news-div">
                    <div className="hot-news-title-div">
                      <img
                        className="hot-news-icon"
                        src="/images/hot-news.svg"
                        alt=""
                      />
                      <p className="hot-news-title">Useful Tips</p>
                    </div>
                    <div className="hot-news-content">
                      <p className="hot-news-content-header">
                        You must enter either a Date of Birth or an age range.
                      </p>
                      <p className="hot-news-content-text">
                        Verification starts with age. Please help us identify
                        the proper person by adding in a date of birth. In cases
                        where you do not have this information, please provide
                        us with an age range to help narrow results.
                      </p>
                    </div>
                    <div className="hot-news-content">
                      <p className="hot-news-content-header">
                        You must enter an Email Address, Username, Phone number
                        or Physical Address.
                      </p>
                      <p className="hot-news-content-text">
                        Certain fields are required to help ensure the accuracy
                        of the results. The more information you provide, such
                        as email addresses, phone numbers, usernames, and
                        physical addresses, the more robust your results will
                        be..{" "}
                      </p>
                    </div>
                    <div className="hot-news-content">
                      <p className="hot-news-content-header">
                        You must include zip code when entering an address
                      </p>
                      <p className="hot-news-content-text">
                        Please don’t forget the zip code when entering an
                        address. This is important, especially when dealing with
                        commonly named people or searching people in large urban
                        areas.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
      <CreateSearchErrorModal canCreateSearchRequests={user.canCreateSearchRequests} mustFillClaimOffice={state.mustFillClaimOffice} />

    </div>
  );
};

export default CreateSearch;
